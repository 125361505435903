dl {
  font-size: 16px;
}

dd {
  margin: 0.2em 0 2em 0;
}

.num-dl {
  counter-reset: dl-counter;
}
.num-dl dt:before {
  content: counter(dl-counter) ". ";
  counter-increment: dl-counter;
}

ul {
  padding: 0;
  list-style: none;
}
