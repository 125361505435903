.form-container {
  max-width: 500px;
  width: 80%;
  margin: 0 auto;
  display: block;
  overflow: hidden;
  padding: 1em;

  .MuiTextField-root,
  .MuiInput-underline {
    width: 100%;
  }

  h1 {
    text-align: center;
  }

  &.confirmation {
    color: #046d04;
    font-size: 1.5em;
  }
}

.conditions {
  line-height: 1.5em;
  ul {
    padding-left: 0;
    list-style: none;
    position: relative;
    left: -12px;
  }

  li {
    padding-bottom: 1.5em;
    // font-size: 16px;
    display: flex;

    div + div {
      padding-top: 0.6em;
      padding-left: 0.6em;
    }

    .mandatory-mark {
      font-weight: bold;
      margin-right: 4px;
    }

    .error-message {
      margin-left: 0;
    }
  }
}

.no-spots {
  color: crimson;
  white-space: nowrap;
  padding-left: 32px;
}

.free-spots {
  color: slateblue;
  white-space: nowrap;
  padding-left: 32px;
}

.term-selection > div > div {
  margin-bottom: 0.5em;
}

span.price {
  display: block;
  padding-left: 32px;
  margin-bottom: 1em;
  color: slateblue;
}

.terms-link {
  display: inline-block;
  padding-top: 0.5em;
}
