@import "./forms.scss";
@import "./lists.scss";
@import "./date-select.scss";
@import "./index/index.scss";
@import "./loaders.scss";
@import "./surveys.scss";
@import "./errors.scss";
@import "./alerts.scss";

body {
  font-size: 1.2em;
}

#main-banner {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto 4rem auto;
  width: 100%;
  min-height: 300px;
  background-image: url("./images/header.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  display: block;
  padding: 300px 0 0;
}

@media (max-width: 1100px) {
  #main-banner {
    padding: 300px 0 0;
  }
}

@media (max-width: 860px) {
  #main-banner {
    padding: 260px 0 0;
  }
}

@media (max-width: 660px) {
  #main-banner {
    padding: 200px 0 0;
  }
}

@media (max-width: 520px) {
  #main-banner {
    padding: 180px 0 0;
  }
}

/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/
