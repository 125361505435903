.ui-invalid input {
  border-color: red;
}

.error-message {
  color: red;
  margin-left: 14px;
  margin-right: 14px;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;

  &.summary {
    margin-bottom: 10px;
    margin-left: 0;
  }
}

.MuiInput-underline + .error-message {
  margin-left: 0;
}

.ui-required:after {
  content: "*";
  display: inline-block;
  margin-left: 4px;
}

.buttons-area {
  &.right {
    text-align: right;
  }

  &.space-between {
    justify-content: space-between;
    display: flex;
  }
}

label {
  cursor: pointer;
  font-size: 16px;
}
