.alert-panel {
  // padding: 10px 16px;
  // margin: 20px 0;

  .icon-wrapper {
    font-size: 22px;
    margin-right: 12px;
    float: left;
  }
}

.alert-warning {
  color: rgb(102, 60, 0);
  background-color: rgb(255, 244, 229);
}

.alert-info {
  color: rgb(13, 60, 97);
  background-color: rgb(232, 244, 253);
}

.alert-error {
  color: rgb(97, 26, 21);
  background-color: rgb(253, 236, 234);

  .icon-wrapper {
    color: red;
  }
}

.alert-success {
  color: rgb(30, 70, 32);
  background-color: rgb(237, 247, 237);
}

.alert {
  position: relative;

  .dismiss-btn {
    position: absolute;
    top: 8px;
    right: 6px;
    padding: 0px;
    min-width: 33px;
    border: none;

    &:hover, &:focus {
      border: none;
    }
  }
}

.error-info {
  color: red;
  font-size: 13px;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  font-style: normal;
}

.MuiFormHelperText-root.Mui-error {
  margin-left: 0 !important;
}
